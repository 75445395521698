//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClickOutside from 'vue-click-outside';
import { mapState } from 'vuex';

import { faCoinVertical } from '@fortawesome/pro-solid-svg-icons';

import {
  faSignIn,
  faBars,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';

import { faMeEarth } from '@motionelements/assets/src/js/me-font/icons-channels2022';

import authMixin from '@motionelements/core/src/components/mixins/auth.mixin.js';

export default {
  name: 'TheNavBarMobile',
  props: {
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [authMixin],
  directives: {
    ClickOutside,
  },
  components: {
    NuxtClientOnly: () => import('@motionelements/core/src/components/nav/NuxtClientOnly.vue'),
    LanguagesList: () => import('@motionelements/core/src/components/modules/common/MenuLanguagesList.vue'),
    TheSidebarMenuMobile: () => import('@/components/core/nav/TheSidebarMenuMobile.vue'),
    UserNavMobile: () => import('@/components/core/nav/UserNavMobile.vue'),
    CustomSidebar: () => import('@motionelements/core/src/components/nav/CustomSidebar.vue'),
  },
  data() {
    return {
      icons: {
        faBars,
        faSignIn,
        faMeEarth,
        faUser,
        faCoinVertical,
      },
    };
  },
  computed: {
    ...mapState({
      member: state => state.user.member,
      isLoggedIn: state => state.user.isLoggedIn,
    }),
    currentRoute() {
      return window.location.href;
    },
  },
};
